<!-- 裁剪图片 -->
<template>
  <div class="wrapper">
      <div class="background">
      	<img :src="imgSrc" width="100%" height="100%" alt="" />
      </div>
	  <div style="height: 1rem;"></div>
	  <div class=topTitle>
          <h1 style="color: #000000;">QRCode is:{{code_qr}}</h1>
		  <h2 style="color: #000000;" v-show="apiFlag=='True'?true : false">{{$t('scan.scantitle')}}</h2>
          <h2 style="color: #000000;" v-show="apiFlag=='False'?true : false">This is not our product.</h2>
          <h2 style="color: #000000;" v-show="apiFlag=='No'?true : false">This QR code has been inquired too many times.</h2>
          <h2 style="color: #000000;" v-show="apiFlag=='Checking'?true : false">Checking</h2>
	  </div>
	  <div style="height: 1rem;"></div>
	  <div class="btnchangeLang">
	  <!-- <van-button round color="#FF0000" plain size="mini" @click="changeCN">中文版</van-button>
	  <van-button round color="#0000FF" plain size="mini"  @click="changeEN">English</van-button> -->
	  </div>
	  <div style="height: 1rem;"></div>
      <div v-show="apiFlag=='True'">
          <h3 style="color: #FFFFFF;">Make sure the camera lens is clean</h3>
          <h3 style="color: #FFFFFF;">Click to Upload the QRCode Image</h3>
      </div>
    <div class="model" v-show="model" @click="model = false">
      <div class="model-show">
        <img :src="modelSrc" alt="">
      </div>
    </div>
    <div class="content" v-show="apiFlag=='True'?true : false">
      <div class="show-info">
        <h2></h2>
        <div class="upload-img">
            <van-uploader 
            :after-read="onRead" 
            v-model="fileList" 
            accept="image/*" 
            :deletable="true" 
            max-count="1" 
            :before-delete="getdelete"
            upload-text="click here to upload"  
            preview-size="300px">
                <img :src="require('/src/assets/upbackground.png')" style="width: 100%; height: 100%;opacity: 0.4;"/>
            </van-uploader>
        </div>
		<label @click="finish2(); show=true" class="btn" style="position: relative;" v-if="ifimg">Submit<br>提交图片</label>
      </div>
    </div>
    <van-overlay :show="show" @click="show = false" >
        <van-loading color="#1989fa"  size="30px" style="margin-right: 16%; margin-top: 50%;"/>
    </van-overlay>
  </div>
</template>

<script>
  import {Dialog} from "vant"
  import {ref} from "vue"
  // import * as OSS from 'ali-oss';
  export default {
    components: {
      [Dialog.Component.name]: Dialog.Component,
    },
    data() {
      return {
        ifimg: false,
        fileList: [],
        model: false,
        modelSrc: '',
        crap: false,
        previews: {},
        show: false,
        code_qr :"",
        apiFlag :"Checking",
        imgSrc:require('/src/assets/newbng.png'),
        form: {
          head: ''
        },
        oriImg: '',
        oriImgType: '',
        apiUrl: "http://back.pokopoko.top:7488/codeDetect/"  
        // apiUrl: "http://127.0.0.1:9799/api/"
      }
    },
    setup(){
        const fileList = ref([
              {
                base64: this.modelSrc,
                deletable: false,
                imageFit: 'contain',
                previewSize: 500,
              },
            ]);
        return { fileList };
    },
    methods: {
        onRead(file) {
            this.ifimg = true;
            this.modelSrc = file.content
            this.oriImg = file
            this.oriImgType = file.file.type
            let canvas = document.createElement('canvas'); // 创建Canvas对象(画布)
            let context = canvas.getContext('2d');
            let img = new Image();
            img.src = file.content;                         // 指定图片的DataURL(图片的base64编码数据)
            img.onload = function () {
                // 画布大小 这里的this指向img
                canvas.width = this.width;
                canvas.height = this.height;
                context.drawImage(img, 0, 0, this.width, this.height);  // 图片大小
                file.content = canvas.toDataURL(file.file.type, 0.45);   // 0.92为默认压缩质量
                this.oriImg = file.content
            };
        },
        convertToBlob(base64){
            console.log(5555555)
            var base64Arr = base64.split(',');
            var imgtype = '';
            var base64String = '';
            console.log(5555555)
            if(base64Arr.length > 1){
                //如果是图片base64，去掉头信息
                base64String = base64Arr[1];
                imgtype = base64Arr[0].substring(base64Arr[0].indexOf(':')+1,base64Arr[0].indexOf(';'));
            }
            console.log(5555555)
            // 将base64解码
            var bytes = atob(base64String);
            //var bytes = base64;
            var bytesCode = new ArrayBuffer(bytes.length);
             // 转换为类型化数组
            var byteArray = new Uint8Array(bytesCode);
            console.log(5555555)
            // 将base64转换为ascii码
            for (var i = 0; i < bytes.length; i++) {
                byteArray[i] = bytes.charCodeAt(i);
            }
            // 生成Blob对象（文件对象）
            console.log(5555555)
            return new Blob( [bytesCode] , {type : imgtype});
        },
        getdelete() {
            this.modelSrc = ""
            this.fileList = []
            this.ifimg = false
        },
        getQR(){
            var kee_Query = this.$route.query;
            if(kee_Query.natqr){
                this.code_qr = kee_Query.natqr
            }
            if(!this.code_qr){
                alert("We haven't got any qrcode.")
                this.apiFlag ="false"
            }else{
                this.apiFlag = "True"
                // this.$http.post(this.apiUrl+"getflag", {"code_qr": this.code_qr}, "application/json")
                // .then(result =>{
                //     var result_body = result["body"]
                //     this.apiFlag = result_body["apiFlag"]
                    
                // }).catch(error=>{console.log(error)})
            }
        },
		changeCN(){
			this.$i18n.locale = "zh"
			sessionStorage.setItem('lang', 'zh')
		},
		changeEN(){
			this.$i18n.locale = "en"
			sessionStorage.setItem('lang', 'en')
		},
      //点击裁剪，这一步是可以拿到处理后的地址
      finish2() {
          var modelSrc = this.oriImg
          console.log(modelSrc.content)
          if(!modelSrc){
              this.show = false
              return void 0
          }
          console.log(modelSrc)
		  var cropName = Math.random().toString(36).substr(2)  // 生成随机字符串记录名字
		  this.$http.post(this.apiUrl+'crop', {"cropSrc":modelSrc.content, "cropName": cropName}, "application/json")
		  .then(result =>{
			if(result["body"]==="crop get"){
                sessionStorage.setItem("cropName",cropName)
                this.$http.post(this.apiUrl+'tqrvert', {"cropName": cropName, "code_qr": this.code_qr}, "application/json")
                .then(result =>{
                    this.show = true
                    var pars = result.body
                    console.log(pars)
                    // this.$router.push({path:'home', params:pars})
                    if (pars.flag){
                        this.$router.push({name:'Home', 
                        params:{QRInfo: pars["QRInfo"], flag: pars["flag"], Standard: pars["Standard"],msg: pars["msg"], InPack: pars["InPack"], QInTime: pars["QinTime"], BatchNum: pars["BatchNum"]}})
                    }else{
                        this.show = false
                        alert("Sorry! We were unable to obtain the information about the QR code. If possible, please try again. ")
                    }
                })
            }else{
                alert("upload failure")
            }
		})
        // this.$http.post(this.apiUrl, {"cropSrc":modelSrc.content, "cropName": cropName}, "application/json")
        //   .then(result =>{
        // 	if(result){
        //     alert("get it")}
        // })
        },
    },
    created: function(){
        this.getQR()
    }
  }
</script>

<style>
  * {
    margin: 0;
    padding: 0;
  }

  .content {
    margin: auto;
    max-width: 585px;
    margin-bottom: 100px;
  }

  .test-button {
    display: flex;
    flex-wrap: wrap;
  }

  .btn {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 1px solid #c0ccda;
    color: #1f2d3d;
    text-align: center;
    box-sizing: border-box;
    outline: none;
    margin: 20px 10px 0px 0px;
    padding: 9px 15px;
    font-size: 14px;
    border-radius: 4px;
    color: #fff;
    background-color: #50bfff;
    border-color: #50bfff;
    transition: all .2s ease;
    text-decoration: none;
    user-select: none;
  }

  .des {
    line-height: 30px;
  }

  code.language-html {
    padding: 10px 20px;
    margin: 10px 0px;
    display: block;
    background-color: #333;
    color: #fff;
    overflow-x: auto;
    font-family: Consolas, Monaco, Droid, Sans, Mono, Source, Code, Pro, Menlo, Lucida, Sans, Type, Writer, Ubuntu, Mono;
    border-radius: 5px;
    white-space: pre;
  }

  .show-info {
    margin-bottom: 50px;
  }

  .show-info h2 {
    line-height: 50px;
  }

  /*.title, .title:hover, .title-focus, .title:visited {
        color: black;
    }*/

  .title {
    display: block;
    text-decoration: none;
    text-align: center;
    line-height: 1.5;
    margin: 20px 0px;
    background-image: -webkit-linear-gradient(left, #3498db, #f47920 10%, #d71345 20%, #f7acbc 30%, #ffd400 40%, #3498db 50%, #f47920 60%, #d71345 70%, #f7acbc 80%, #ffd400 90%, #3498db);
    color: transparent;
    -webkit-background-clip: text;
    background-size: 200% 100%;
    animation: slide 5s infinite linear;
    font-size: 40px;
  }

  .test {
    height: 285px;
  }

  .model {
    position: fixed;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
  }

  .model-show {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }

  .model img {
    display: block;
    margin: auto;
    max-width: 80%;
    user-select: none;
    background-position: 0px 0px, 10px 10px;
    background-size: 20px 20px;
    background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%), linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%);
  }

  .c-item {
    display: block;
    padding: 10px 0;
    user-select: none;
  }

  @keyframes slide {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: -100% 0;
    }
  }

  @media screen and (max-width: 1000px) {
    .content {
      max-width: 90%;
      margin: auto;
    }

    .test {
      height: 400px;
    }
  }
</style>