<template>
	<div class="RootClass">
		<div class="TopTitle">
			<h1>{{$t('pack.packtitle')}}</h1>
		</div>
		<div class="btnchangeLang">
		<van-button round color="#FF0000" plain size="mini" @click="changeCN">中文版</van-button>
		<van-button round color="#0000FF" plain size="mini"  @click="changeEN">English</van-button>
		</div>
		<div style="height: 1rem;"></div>
		<div class="ShortPack">
			<p style="font-size: 150%; margin: 0 2rem 1rem 2rem;">{{$t('pack.packintro')}}</p>
		</div>
		<div class="PopupPack">
			<van-cell is-link @click="showPopup1" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong style="font-size: 125%;">{{$t('pack.packbox')}}</strong></van-cell>
			<van-popup v-model="showBox" position="top" :style="{ height: '60%' }">
				<h1>{{$t('pack.boxpop1')}}</h1>
				<img v-for="img in imgBox" :key="img.id" v-lazy="img" width="60%"/>
				<p>{{$t('pack.boxpop2')}}</p>
			</van-popup>
			<van-cell is-link @click="showPopup2" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong style="font-size: 125%;">{{$t('pack.packbag')}}</strong></van-cell>
			<van-popup v-model="showBag" position="top" :style="{ height: '60%' }">
				<h1>{{$t('pack.bagpop1')}}</h1>
				<img v-for="img in imgBag1" :key="img.id" v-lazy="img"/>
				<p>{{$t('pack.bagpop2')}}</p>
				<h1>{{$t('pack.bagpop3')}}</h1>
				<img v-for="img in imgBag2" :key="img.id" v-lazy="img"/>
				<p>{{$t('pack.bagpop4')}}</p>
			</van-popup>
			<van-cell is-link @click="showPopup3" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong style="font-size: 125%;">{{$t('pack.packdrum')}}</strong></van-cell>
			<van-popup v-model="showDrum" position="top" :style="{ height: '60%' }">
				<h1>{{$t('pack.drumpop1')}}</h1>
				<img v-for="img in imgDrum1" :key="img.id" v-lazy="img"/>
				<p>{{$t('pack.drumpop2')}}</p>
				<h1>{{$t('pack.drumpop3')}}</h1>
				<img v-for="img in imgDrum2" :key="img.id" v-lazy="img"/>
				<p>{{$t('pack.drumpop4')}}</p>
			</van-popup>
			<van-cell is-link @click="showPopup4" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong style="font-size: 125%;">{{$t('pack.conpack')}}</strong></van-cell>
			<van-popup v-model="showContainer" position="top" :style="{ height: '60%' }">
				<img v-for="img in imgCon" :key="img.id" v-lazy="img"/>
				<h1>{{$t('pack.compop1')}} </h1>
				<p style="text-align: left; margin: 0 2rem 4px 2rem;">{{$t('pack.compop2')}}</p>
				<h1>{{$t('pack.compop3')}} </h1>
				<p style="text-align: left; margin: 0 2rem 4px 2rem;">{{$t('pack.compop4')}}</p>
			</van-popup>
		</div>
		<div class="ShortPackDetails">
			<p id="all" style="font-size: 110%;color: #00cc00;text-align: left; margin: 1rem auto;text-align: center ;">{{$t('pack.conclusion')}}</p>
		</div>
		<div style="height: 2rem;"></div>
		<div class= "BottomGuide">
			<router-view />
			<van-tabbar route active-color="#00cc00">
			  <van-tabbar-item replace to="/home" icon="home-o">{{$t("bottom.home")}}</van-tabbar-item>
			  <van-tabbar-item replace to="/main" icon="star-o">{{$t("bottom.main")}}</van-tabbar-item>
			  <van-tabbar-item replace to="/advan" icon="fire-o" name="advan-q">{{$t("bottom.advan")}}</van-tabbar-item>
			  <van-tabbar-item replace to="/pack" icon="bag-o" name="package-q">{{$t("bottom.pack")}}</van-tabbar-item>
			</van-tabbar>
		</div>
		<div style="height: 5rem;"></div>
	</div>
<!-- 	</div> -->
</template>

<script>
	export default {
	  data() {
	    return {
			showBox: false,
			showBag: false,
			showDrum: false,
			showContainer: false,
			imgBox: [require("/src/assets/box.jpg")],
			imgBag1: [require("/src/assets/lvdaizi.jpg")],
			imgBag2: [require("/src/assets/jizhuangdai.jpg")],
			imgDrum1: [require("/src/assets/papertong.jpg")],
			imgDrum2: [require("/src/assets/irontong.gif")],
			imgCon: [require("/src/assets/jizhuangxiang.jpg")],
	    };
	  },
	  methods: {
		  showPopup1() {
		      this.showBox = true;
		  },
		  showPopup2() {
			  this.showBag = true;
		  },
		  showPopup3() {
			  this.showDrum = true;
		  },
		  showPopup4() {
			  this.showContainer = true;
		  },
		  changeCN(){
				  this.$i18n.locale = "zh"
		  },
		  changeEN(){
			  this.$i18n.locale = "en"
		  },
	    },
	};
</script>

<style>
	 .background{
	      width:100%;  
	      height:100%;  /**宽高100%是为了图片铺满屏幕 */
	      z-index:-1;
	      position: absolute;
	  }
	   
	  .RootClass{
	      z-index:1;
	      position: relative;
	  }
</style>
