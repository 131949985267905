<template>
	<!-- <div class="ConRootClass">
	<div class="background">
		<img :src="imgSrc" width="100%" height="100%" alt="" />
	</div> -->
	<div class="RootClass">
		<div class="TopTitle">
			<h1>{{$t('advan.toptitle')}}</h1>
		</div>
		<div class="btnchangeLang">
		<van-button round color="#FF0000" plain size="mini" @click="changeCN">中文版</van-button>
		<van-button round color="#0000FF" plain size="mini"  @click="changeEN">English</van-button>
		</div>
		<div style="height: 1rem;"></div>
		<div class= "BottomGuide">
			<router-view />
			<van-tabbar route active-color="#00cc00">
			  <van-tabbar-item replace to="/home" icon="home-o">{{$t("bottom.home")}}</van-tabbar-item>
			  <van-tabbar-item replace to="/main" icon="star-o">{{$t("bottom.main")}}</van-tabbar-item>
			  <van-tabbar-item replace to="/advan" icon="fire-o" name="advan-q">{{$t("bottom.advan")}}</van-tabbar-item>
			  <van-tabbar-item replace to="/pack" icon="bag-o" name="package-q">{{$t("bottom.pack")}}</van-tabbar-item>
			</van-tabbar>
		</div>
		<div class="ShortAdvan">
			<p style="text-align: center;margin-left: 2rem;margin-right: 2rem; font-size: 125%;">
				<strong>{{$t('advan.shortIntro')}}</strong>
			</p>
		</div>
		<div class="AdvanList">
			<ul style="margin-bottom: 1rem; color:#00cc00;font-weight: 600;">
				<li style="text-align: left;margin:0 2rem 5px 2rem;">1.&nbsp;&nbsp;{{$t('advan.line1')}}</li>
				<li style="text-align: left;margin:0 2rem 5px 2rem;">2.&nbsp;&nbsp;{{$t('advan.line2')}}</li>
				<li style="text-align: left;margin:0 2rem 5px 2rem;">3.&nbsp;&nbsp;{{$t('advan.line3')}}</li>
				<li style="text-align: left;margin:0 2rem 5px 2rem;">4.&nbsp;&nbsp;{{$t('advan.line4')}}</li>
				<li style="text-align: left;margin:0 2rem 5px 2rem;">5.&nbsp;&nbsp;{{$t('advan.line5')}}</li>
			</ul>
		</div>
		<div class="AdvanImg">
			<img v-for="img in imageList" :key="img.id" v-lazy="img" style="width: 60%;"/>
		</div>
		<div class="SpecificAdvan">
			<h2 id="juti" style="text-align: center;font-size: 150%;margin-top: 1rem;">{{$t('advan.specific')}}</h2>
			        <p id="jutis" style="text-align: center; font-size: 125%; margin-left: 2rem;margin-right: 2rem;">{{$t('advan.specificdetail')}}</p>
			        <ul>
			            <li style="text-align: left; margin:0 2rem 5px 2rem;"><strong style="color:#00cc00;">1)</strong> {{$t('advan.sline1')}}</li>
			            <li style="text-align: left; margin:0 2rem 5px 2rem;"><strong style="color:#00cc00;">2)</strong> {{$t('advan.sline2')}}</li>
			            <li style="text-align: left; margin:0 2rem 5px 2rem;"><strong style="color:#00cc00;">3)</strong> {{$t('advan.sline3')}}</li>
			            <li style="text-align: left; margin:0 2rem 5px 2rem;"><strong style="color:#00cc00;">4)</strong> {{$t('advan.sline4')}}</li>
			            <li style="text-align: left; margin:0 2rem 5px 2rem;"><strong style="color:#00cc00;">5)</strong> {{$t('advan.sline5')}}</li>
			            <li style="text-align: left; margin:0 2rem 5px 2rem;"><strong style="color:#00cc00;">6)</strong> {{$t('advan.sline6')}}</li>
			        </ul>
		</div>
		<div style="height: 5rem;"></div>
	</div>
	<!-- </div> -->
</template>

<script>
	export default {
	  data() {
	    return {
		  
	      imageList: [
			  require('/src/assets/advantageN.jpg'),
	      ],
	    };
	  },
	  methods:{
		  changeCN(){
		  		  this.$i18n.locale = "zh"
		  },
		  changeEN(){
		  	  this.$i18n.locale = "en"
		  },
	  },
	};
</script>

<style>
	.background{
	    width:100%;  
	    height:100%;  /**宽高100%是为了图片铺满屏幕 */
	    z-index:-1;
	    position: absolute;
	}
	 
	.RootClass{
	    z-index:1;
	    position: relative;
	}
</style>
