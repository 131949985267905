<template>
	<!-- <div class="ConRootClass">
	<div class="background">
		<img :src="imgSrc" width="100%" height="100%" alt="" />
	</div> -->
	<div class="RootClass">
	<div class="TopTitle">
		<h1>{{$t('main.maintitle')}}</h1>
	</div>
	<div class="btnchangeLang">
	<van-button round color="#FF0000" plain size="mini" @click="changeCN">中文版</van-button>
	<van-button round color="#0000FF" plain size="mini"  @click="changeEN">English</van-button>
	</div>
	<div style="height: 1rem;"></div>
	<div class="ShortImg">
		<img v-for="img in imageList" :key="img.id" v-lazy="img" style="width: 60%;"/>
	</div>
	<div class="ShortIntro">
		<p style="color: #00cc00; margin-left: 2rem; margin-right: 2rem; text-align: left;font-weight: 600;">
			&nbsp;&nbsp;&nbsp;&nbsp;{{$t('main.shortintro1')}}</p>
		<p style="color: #00cc00; margin-left: 2rem; margin-right: 2rem; text-align: left;font-weight: 600;">&nbsp;&nbsp;&nbsp;&nbsp;{{$t('main.shortintro2')}}</p>
		<p style="color: #00cc00; margin-left: 2rem; margin-right: 2rem; text-align: left;font-weight: 600;">&nbsp;&nbsp;&nbsp;&nbsp;{{$t('main.shortintro3')}}</p>
		<p style="color: #00cc00; margin-left: 2rem; margin-right: 2rem; text-align: left;font-weight: 600;">&nbsp;&nbsp;&nbsp;&nbsp;{{$t('main.shortintro4')}}</p> 
		<p style="color: #00cc00; margin-left: 2rem; margin-right: 2rem; text-align: left;font-weight: 600;">&nbsp;&nbsp;&nbsp;&nbsp;{{$t('main.shortintro5')}}</p>
	</div>
	<div class= "Collaps">
		<van-collapse v-model="activeName" accordion>
		  <van-collapse-item name="1">
			  <template #title>
			        <div style="font-size: 125%;">{{$t('main.collaps1_title')}}</div>
			      </template>
			  <p style="color: #D26900; text-align: left;margin-left: 2rem;margin-right: 2rem;">&nbsp;&nbsp;&nbsp;{{$t("main.collaps1_1")}}</p>
			  <van-cell is-link @click="showPopup">{{$t('main.collaps1_out')}}</van-cell>
			  <van-popup v-model="show" position="top" :style="{ height: '65%' }">
				  <table align="center" border="1" cellpadding="0" cellspacing="1" style="margin-top: 5rem;">
				  					<tbody>
				  						<tr>
				  							<td id="to11" nowrap="nowrap" style="width: 60px; height: 19px; text-align: center;">
				  								{{$t('main.collaps1t_to11_1')}}<br>{{$t('main.collaps1t_to11_2')}}</td>
				  							<td id="to12" nowrap="nowrap" style="width: 50px; height: 19px; text-align: center;">
				  								{{$t('main.collaps1t_to12_1')}}<br>{{$t('main.collaps1t_to12_2')}}</td>
				  							<td id="to13" nowrap="nowrap" style="width: 50px; height: 19px; text-align: center;">
				  								{{$t('main.collaps1t_to13_1')}} <br>{{$t('main.collaps1t_to13_2')}}</td>
				  							<td id="to14" nowrap="nowrap" style="width: 50px; height: 19px; text-align: center;">
				  								{{$t('main.collaps1t_to14_1')}} <br>{{$t('main.collaps1t_to14_2')}}</td>
				  						</tr>
				  						<tr>
				  							<td id="to21" nowrap="nowrap" style=" text-align: center;">
				  								3A</td>
				  							<td id="to22" nowrap="nowrap" style=" text-align: center;">
				  								3</td>
				  							<td id="to23" nowrap="nowrap" style=" text-align: center;">
				  								H<sub>2</sub>O</td>
				  							<td id="to24" nowrap="nowrap" style=" text-align: center;">
				  								{{$t('main.collaps1t_to24_1')}}</td>
				  						</tr>
				  						<tr>
				  							<td id="to31" nowrap="nowrap" style=" text-align: center;">
				  								4A</td>
				  							<td id="to32" nowrap="nowrap" style=" text-align: center;">
				  								4</td>
				  							<td id="to33" nowrap="nowrap" style=" text-align: center;">
				                                  H<sub>2</sub>O,{{$t('main.collaps1t_to33_1')}}</td>
				  							<td id="to34" nowrap="nowrap" style=" text-align: center;">
				  								{{$t('main.collaps1t_to34_1')}}{{$t('main.collaps1t_to34_2')}}<br>{{$t('main.collaps1t_to34_3')}}</td>
				  						</tr>
				  						<tr>
				  							<td id="to41" nowrap="nowrap" style=" text-align: center;">
				  								13X</td>
				  							<td id="to42" nowrap="nowrap" style=" text-align: center;">
				  								8.5</td>
				  							<td id="to43" nowrap="nowrap" style=" text-align: center;">
				  								H<sub>2</sub>O,<br>{{$t('main.collaps1t_to43_1')}},<br>{{$t('main.collaps1t_to43_2')}}</td>
				  							<td id="to44" nowrap="nowrap" style=" text-align: center;">
				  								{{$t('main.collaps1t_to43_1')}}</td>
				  						</tr>
				  						<tr>
				  							<td id="to51" nowrap="nowrap" style=" text-align: center;">
				  								{{$t('main.collaps1t_to51_1')}}<br>{{$t('main.collaps1t_to51_2')}}</td>
				  							<td id="to52" nowrap="nowrap" style=" text-align: center;">
				  								200-300</td>
				  							<td id="to53" nowrap="nowrap" style=" text-align: center;">
				  								{{$t('main.collaps1t_to53_1')}}</td>
				  							<td id="to54" nowrap="nowrap" style=" text-align: center;">
				  								{{$t('main.collaps1t_to54_1')}}</td>
				  						</tr>
				  					</tbody>
				  				</table>
			  </van-popup>
		  </van-collapse-item>
		  <van-collapse-item name="2">
			  <template #title>
			    <div style="font-size: 125%;">{{$t('main.collaps2_title')}}</div>
			  </template>
			  <p style="color: #D26900; text-align: left; margin-left: 2rem;margin-right: 2rem;">&nbsp;&nbsp;&nbsp;&nbsp;{{$t('main.collaps2_1')}}</p>
			  <p style="color: #D26900; text-align: left; margin-left: 2rem;margin-right: 2rem;">&nbsp;&nbsp;&nbsp;{{$t('main.collaps2_2')}}</p>
			  <van-cell is-link @click="showPopup">{{$t('main.collaps2_out')}}</van-cell>
			  <van-popup v-model="show" position="top" :style="{ height: '65%' }">
				  <table border="1" cellpadding="0" cellspacing="0" style="font-size: 75%; margin-top: 5rem;">
				  					<tbody>
				  						<tr>
				  							<td colspan="2" rowspan="2" style="width:100px;height:19px;text-align: center" id="tw11">
				  								{{$t('main.collaps2t_tw11')}}</td>
				  							<td colspan="2" style="width: 128px; height: 19px; text-align: center;">
				  								d(0.5-0.9mm)</td>
				  							<td colspan="2" style="width: 128px; height: 19px; text-align: center;">
				  								d(0.9-1.4mm)</td>
				  							<td colspan="2" style="width: 128px; height: 19px; text-align: center;">
				  								d(1.4-2.0mm)</td>
				  						</tr>
				  						<tr>
				  							<td id="tw12" style="text-align: center;">
				  								{{$t('main.collaps2t_P')}}</td>
				  							<td id="tw13" style="text-align: center;">
				  								{{$t('main.collaps2t_Q')}}</td>
				  							<td id="tw14" style="text-align: center;">
				  								{{$t('main.collaps2t_P')}}</td>
				  							<td id="tw15" style="text-align: center;">
				  								{{$t('main.collaps2t_Q')}}</td>
				  							<td id="tw16" style="text-align: center;">
				  								{{$t('main.collaps2t_P')}}</td>
				  							<td id="tw17" style="text-align: center;">
				  								{{$t('main.collaps2t_Q')}}</td>
				  						</tr>
				  						<tr>
				  							<td colspan="2" style="text-align: center;" id="tw21">
				  								{{$t('main.collaps2t_tw21')}}</td>
				  							<td colspan="6" style="text-align: center;" id="tw22">
				  								{{$t('main.collaps2t_tw22')}}</td>
				  						</tr>
				  						<tr>
				  							<td style="text-align: center;" id="tw31">
				  								{{$t('main.collaps2t_tw31')}}</td>
				  							<td style="text-align: center;">
				  								≧</td>
				  							<td colspan="6" style="text-align: center;">
				  								0.7</td>
				  						</tr>
				  						<tr>
				  							<td style="text-align: center;" id="tw41">
				  								{{$t('main.collaps2t_tw41')}}<br>【RH10%,(25&plusmn;1)℃】/%</td>
				  							<td style="text-align: center;" >
				  								≧</td>
				  							<td style="text-align: center;" >
				  								16</td>
				  							<td style="text-align: center;" >
				  								15</td>
				  							<td style="text-align: center;" >
				  								16</td>
				  							<td style="text-align: center;">
				  								15</td>
				  							<td style="text-align: center;">
				  								16</td>
				  							<td style="text-align: center;">
				  								15</td>
				  						</tr>
				  						<tr>
				  							<td style="text-align: center;" id="tw51">
				  								{{$t('main.collaps2t_tw51')}}</td>
				  							<td style="text-align: center;">
				  								≧</td>
				  							<td style="ext-align: center;">
				  								93</td>
				  							<td style="text-align: center;">
				  								88</td>
				  							<td style="text-align: center;">
				  								96</td>
				  							<td style="text-align: center;">
				  								95</td>
				  							<td style="text-align: center;">
				  								90</td>
				  							<td style="text-align: center;">
				  								89</td>
				  						</tr>
				  						<tr>
				  							<td style="text-align: center;" id="tw61">
				  								{{$t('main.collaps2t_tw61')}}</td>
				  							<td style="text-align: center;">
				  								≦</td>
				  							<td colspan="6" style="text-align: center;">
				  								2</td>
				  						</tr>
				  						<tr>
				  							<td style="text-align: center;" id="tw71">
				  								{{$t('main.collaps2t_tw71')}}</td>
				  							<td style="text-align: center;">
				  								≦</td>
				  							<td colspan="6" style="text-align: center;">
				  								1.5</td>
				  						</tr>
				  						<tr>
				  							<td style="text-align: center;" id="tw81">
				  								{{$t('main.collaps2t_tw81')}}</td>
				  							<td style="text-align: center;">
				  								≦</td>
				  							<td style="text-align: center;">
				  								0.5</td>
				  							<td style="text-align: center;">
				  								0.7</td>
				  							<td colspan="2" style="text-align: center;">
				  								0.5</td>
				  							<td colspan="2" style="text-align: center;">
				  								0.5</td>
				  						</tr>
				  						<tr>
				  							<td style="text-align: center;" id="tw91">
				  								{{$t('main.collaps2t_tw91')}}</td>
				  							<td style="text-align: center;">
				  								≦</td>
				  							<td colspan="6" style="text-align: center;">
				  								0.3</td>
				  						</tr>
				  						<tr>
				  							<td style="text-align: center;" id="tw101">
				  								{{$t('main.collaps2t_tw101')}}</td>
				  							<td style="text-align: center;">
				  								≧</td>
				  							<td style="text-align: center;">
				  								40</td>
				  							<td style="text-align: center;">
				  								35</td>
				  							<td style="text-align: center;">
				  								40</td>
				  							<td style="text-align: center;">
				  								35</td>
				  							<td style="text-align: center;">
				  								40</td>
				  							<td style="text-align: center;">
				  								35</td>
				  						</tr>
				  						<tr>
				  							<td style="text-align: center;" id="tw111">
				  								{{$t('main.collaps2t_tw111')}}</td>
				  							<td style="text-align: center;">
				  								≦</td>
				  							<td style="text-align: center;">
				  								0.01</td>
				  							<td style="text-align: center;">
				  								0.04</td>
				  							<td style="text-align: center;">
				  								0.01</td>
				  							<td style="text-align: center;">
				  								0.04</td>
				  							<td style="text-align: center;">
				  								0.01</td>
				  							<td style="text-align: center;">
				  								0.04</td>
				  						</tr>
				  					</tbody>
				  				</table>
			</van-popup>
		  </van-collapse-item>
		  <van-collapse-item name="3">
			  <template #title>
			    <div style="font-size: 125%;">{{$t('main.collaps3_title')}}</div>
			  </template>
			<ul style="color: #D26900;margin-left: 2rem;margin-right: 2rem;">
            <li id="line71" style="text-align: left;"><strong>1)</strong> {{$t('main.collaps3_line1')}}</li>
            <li id="line72" style="text-align: left;"><strong>2)</strong> {{$t('main.collaps3_line2')}}</li>
            <li id="line73" style="text-align: left;"><strong>3)</strong> {{$t('main.collaps3_line3')}}</li>
            <li id="line74" style="text-align: left;"><strong>4)</strong> {{$t('main.collaps3_line4')}}</li>
            <li id="line75" style="text-align: left;"><strong>5)</strong> {{$t('main.collaps3_line5')}}</li>
            <li id="line76" style="text-align: left;"><strong>6)</strong> {{$t('main.collaps3_line6')}}</li>
            <li id="line77" style="text-align: left;"><strong>7)</strong> {{$t('main.collaps3_line7')}}</li>
            </ul>
		  </van-collapse-item>
		</van-collapse>
	</div>
	<div class= "BottomGuide">
		<router-view />
		<van-tabbar route active-color="#00cc00">
		  <van-tabbar-item replace to="/home" icon="home-o">{{$t("bottom.home")}}</van-tabbar-item>
		  <van-tabbar-item replace to="/main" icon="star-o">{{$t("bottom.main")}}</van-tabbar-item>
		  <van-tabbar-item replace to="/advan" icon="fire-o" name="advan-q">{{$t("bottom.advan")}}</van-tabbar-item>
		  <van-tabbar-item replace to="/pack" icon="bag-o" name="package-q">{{$t("bottom.pack")}}</van-tabbar-item>
		</van-tabbar>
	</div>
	<div style="height: 5rem;"></div>
	</div>
	<!-- </div> -->
</template>

<script>
	export default {
	  data() {
	    return {
	      activeName: '0',
		  imageList: [require('/src/assets/jianjie.gif'),],
		  show: false,
	    };
	  },
	  methods: {
	      showPopup() {
	        this.show = true;
	      },
		  changeCN(){
		  				  this.$i18n.locale = "zh"
		  },
		  changeEN(){
		  			  this.$i18n.locale = "en"
		  },
	    },
	};
	
</script>

<style>
	 .background{
	      width:100%;  
	      height:100%;  /**宽高100%是为了图片铺满屏幕 */
	      z-index:-1;
	      position: absolute;
	  }
	   
	  .RootClass{
	      z-index:1;
	      position: relative;
	  }
</style>
